import React, { Component } from 'react';
import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFound extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Jennifer Moné Hill Artist - 404"/>
        <h2>Sorry, this is not the page that you are looking for.</h2>
      </Layout>
    );
  }
}

export default NotFound;
